import { computed, ref, watch } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default function useCustomerOrderList(customerId) {
    // Use toast
    const toast = useToast();

    const refOrderListTable = ref(null);

    const showOnHold = ref(true);

    // Table Handlers
    const tableColumns = [
        // {
        //     key: "shop",
        //     label: "Shop",
        //     sortable: false,
        // },
        {
            key: "id",
            label: "ID",
            sortable: false,
        },
        {
            key: "total",
            sortable: false,
            formatter: val => `$${val.toLocaleString()}`,
        },
        {
            key: "originCreateDate",
            label: "Issued Date",
            sortable: true,
        },
        {
            key: "status",
            sortable: false,
        },
        {
            key: "shipment",
            label: "Shipment",
            sortable: false,
        },
        {
            key: "actions",
        },
    ];
    const perPage = ref(25);
    const totalOrders = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [25, 50, 100];
    const searchQuery = ref("");
    const shopFilter = ref(null);
    const sortBy = ref("originCreateDate");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refOrderListTable.value ? refOrderListTable.value.localItems.length : 0;

        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalOrders.value,
        };
    });

    const refetchData = () => {
        refOrderListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statusFilter, showOnHold, shopFilter], () => {
        refetchData();
    });

    const fetchOrders = (ctx, callback) => {
        store
            .dispatch("ecommerce-customer/fetchCustomer", customerId)
            .then(response => {
                const { orders } = response.data;

                callback(orders);

                totalOrders.value = response.data.orders.length;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching orders' list",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveOrderStatusVariantAndIcon = status => {
        if (status === "on-hold") {
            return {
                variant: "warning",
                icon: "PieChartIcon",
            };
        }

        if (status === "processing") {
            return {
                variant: "success",
                icon: "CheckCircleIcon",
            };
        }

        if (status === "delivered") {
            return {
                variant: "info",
                icon: "ArrowDownCircleIcon",
            };
        }

        if (status === "completed") {
            return {
                variant: "primary",
                icon: "SaveIcon",
            };
        }

        if (status === "shipped") {
            return {
                variant: "secondary",
                icon: "SendIcon",
            };
        }

        if (status === "partial-shipped") {
            return {
                variant: "danger",
                icon: "InfoIcon",
            };
        }

        if (status === "pending") {
            return {
                variant: "info",
                icon: "InfoIcon",
            };
        }

        return {
            variant: "secondary",
            icon: "XIcon",
        };
    };

    const resolveClientAvatarVariant = status => {
        if (status === "on-hold") {
            return "warning";
        }

        if (status === "processing") {
            return "success";
        }

        if (status === "delivered") {
            return "info";
        }

        if (status === "completed") {
            return "primary";
        }

        if (status === "shipped") {
            return "secondary";
        }

        if (status === "partial-shipped") {
            return "danger";
        }

        return "secondary";
    };

    return {
        fetchOrders,
        tableColumns,
        showOnHold,
        perPage,
        currentPage,
        totalOrders,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refOrderListTable,

        statusFilter,
        shopFilter,

        resolveOrderStatusVariantAndIcon,
        resolveClientAvatarVariant,

        refetchData,
    };
}
