<template>
    <!-- Table Container Card -->
    <b-card no-body>
        <div class="m-2">
            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <div class="d-flex align-items-center justfiy-content-center">
                        <!-- <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." /> -->
                        <b-button variant="primary" :to="{ name: 'ecommerce-order-add' }" class="mr-1 w-100">
                            Add Order
                        </b-button>
                    </div>
                    <!-- <label>Entries</label>
                    <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                    <b-form-checkbox v-if="!statusFilter" v-model="showOnHold" class="custom-control-warning">
                        Show On-Hold
                    </b-form-checkbox> -->
                </b-col>

                <!-- Search -->
                <!-- <b-col cols="12" md="6"> -->
                <!-- <div
                        class="d-flex flex-sm-row align-items-center justify-content-end invoice-filter-select-wrapper"
                    > -->
                <!-- <v-select
                            v-model="statusFilter"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            :reduce="val => val.value"
                            class="invoice-filter-select mr-1"
                            placeholder="Select Status"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select> -->
                <!-- </div> -->
                <!-- </b-col> -->
            </b-row>
        </div>

        <b-table
            ref="refOrderListTable"
            :items="fetchOrders"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
        >
            <!-- Column: Shop -->
            <!-- <template #cell(shop)="data" class="d-flex align-items-center justify-content-center">
                <b-avatar
                    size="38"
                    :src="data.item.avatar"
                    :text="avatarText(data.item.shopName)"
                    :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
                />
            </template> -->
            <!-- Column: Id -->
            <template #cell(id)="data">
                <b-link
                    :id="`id-order-${data.item.id}`"
                    :to="{
                        name: 'ecommerce-order-preview',
                        params: { id: data.item.id },
                    }"
                    class="font-weight-bold"
                >
                    #{{ data.item.originId }}
                </b-link>
                <!-- <b-tooltip :target="`id-order-${data.item.id}`" triggers="hover">
                    <a :href="data.item.shopUrl" class="text-white"
                        ><b>{{ data.item.shopName }}</b></a
                    >
                </b-tooltip> -->
            </template>

            <!-- Column: Customer -->
            <template #cell(customerNameSurname)="data">
                <b-media vertical-align="center">
                    <template #aside />
                    <b-link
                        :id="`id-order-${data.item.id}`"
                        :to="{
                            name: 'ecommerce-order-preview',
                            params: { id: data.item.id },
                        }"
                        class="font-weight-bold"
                    >
                        <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.customerNameSurname }}
                        </span>
                    </b-link>

                    <small class="text-muted">{{ data.item.email }}</small>
                </b-media>
            </template>

            <template #cell(total)="data">
                <span class="text-nowrap">
                    {{ data.item.total | formatCurrency }}
                </span>
            </template>

            <!-- Column: Issued Date -->
            <template #cell(originCreateDate)="data">
                <span class="text-nowrap text-capitalize">
                    {{ data.item.originCreateDate | formatDate }}
                </span>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
                <template>
                    <b-badge
                        pill
                        :variant="`light-${resolveOrderStatusVariantAndIcon(data.value).variant}`"
                        class="text-capitalize"
                    >
                        {{ data.value }}
                    </b-badge>
                </template>
            </template>

            <!-- Column: Shipment -->
            <template #cell(shipment)="data">
                <template v-if="data.value">
                    <div v-for="item in data.value" :key="item.tracking_number">
                        <span class="font-weight-bolder d-block text-nowrap text-uppercase">
                            {{ item.tracking_provider | formatShipment }}
                        </span>
                        <small class="text-muted">{{ item.tracking_number }}</small>
                    </div>
                </template>
                <template v-else>
                    <div>
                        <span class="font-weight-bold d-block text-nowrap"> No shipment data </span>
                    </div>
                </template>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <feather-icon
                        :id="`order-row-${data.item.id}-sync-icon`"
                        icon="RefreshCwIcon"
                        size="16"
                        class="mx-1"
                        @click="syncOrder(data.item.id)"
                    />
                    <b-link
                        :to="{
                            name: 'ecommerce-order-edit',
                            params: {
                                id: data.item.id,
                            },
                        }"
                    >
                        <feather-icon
                            :id="`order-row-${data.item.id}-preview-icon`"
                            icon="EditIcon"
                            size="16"
                            class="mx-1"
                        />
                    </b-link>
                </div>
            </template>
        </b-table>
        <!-- Endpoint doesn't provide us these data -->
        <!-- <div class="mx-2 mb-2"> -->
        <!-- <b-row> -->
        <!-- <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted"
                        >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                    >
                </b-col> -->
        <!-- Pagination -->
        <!-- <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrders"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col> -->
        <!-- </b-row> -->
        <!-- </div> -->
    </b-card>
</template>

<script>
import {
    // BAvatar,
    BBadge,
    BCard,
    BCol,
    // BFormCheckbox,
    BButton,
    // BFormInput,
    BLink,
    BMedia,
    // BPagination,
    BRow,
    BTable,
    // BTooltip,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
// import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import useCustomerOrderList from "./useCustomerOrderList";
import router from "@/router";
import customerStoreModule from "../customerStoreModule";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        // BFormInput,
        // BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        // BAvatar,
        BLink,
        BBadge,
        // BPagination,
        // BTooltip,

        // vSelect,
    },
    setup() {
        const toast = useToast();

        const ECOMMERCE_ORDER_STORE_MODULE_NAME = "ecommerce-order";

        // Register module
        if (!store.hasModule(ECOMMERCE_ORDER_STORE_MODULE_NAME)) {
            store.registerModule(ECOMMERCE_ORDER_STORE_MODULE_NAME, customerStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(ECOMMERCE_ORDER_STORE_MODULE_NAME)) {
                store.unregisterModule(ECOMMERCE_ORDER_STORE_MODULE_NAME);
            }
        });

        const statusOptions = [
            {
                label: "On-Hold",
                value: "on-hold",
            },
            {
                label: "Processing",
                value: "processing",
            },
            {
                label: "Partial Shipped",
                value: "partial-shipped",
            },
            {
                label: "Completed",
                value: "completed",
            },
            {
                label: "Cancelled",
                value: "cancelled",
            },
            {
                label: "Pending",
                value: "pending",
            },
        ];

        const {
            fetchOrders,
            tableColumns,
            showOnHold,
            perPage,
            currentPage,
            totalOrders,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refOrderListTable,

            statusFilter,
            shopFilter,

            refetchData,

            resolveOrderStatusVariantAndIcon,
            resolveClientAvatarVariant,
        } = useCustomerOrderList(router.currentRoute.params.id);

        const syncOrder = id => {
            store.dispatch("ecommerce-order/syncOrder", id).then(() => {
                refetchData();
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Order is synced successfully",
                        icon: "CheckCircleIcon",
                        variant: "success",
                    },
                });
            });
        };

        return {
            fetchOrders,
            tableColumns,
            showOnHold,
            perPage,
            currentPage,
            totalOrders,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refOrderListTable,
            statusFilter,
            shopFilter,

            refetchData,

            statusOptions,

            syncOrder,

            avatarText,
            resolveOrderStatusVariantAndIcon,
            resolveClientAvatarVariant,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 180px;
    //   margin-right: 2%;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 80px;
    }
}

// x-small
@media screen and (max-width: 576px) {
    .invoice-filter-select {
        min-width: 90px;
        width: 100%;
        margin-top: 0.75rem;
    }
    .invoice-filter-select-wrapper {
        flex-wrap: wrap;
    }
}

// small
@media screen and (max-width: 768px) {
    .invoice-filter-select {
        min-width: 100px;
        ::v-deep .vs__selected {
            width: 45px;
        }
    }
}

// medium
@media screen and (max-width: 992px) {
    .invoice-filter-select {
        min-width: 120px;
        ::v-deep .vs__selected {
            width: 40px;
        }
    }
}
// large
@media scren and (max-width: 1200px) {
    .invoice-filter-select {
        min-width: 140px;
        width: 140px;
        ::v-deep .vs__selected {
            width: 60px;
        }
    }
}
// xlarge
@media screen and (min-width: 1400px) {
    .invoice-filter-select {
        // min-width: 180px;
        ::v-deep .vs__selected {
            width: 80px;
        }
    }
}
// xxLarge
@media screen and (min-width: 1600px) {
    .invoice-filter-select {
        min-width: 170px;
        ::v-deep .vs__selected {
            width: 80px;
        }
    }
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
// center td
</style>
