<template>
    <b-card>
        <b-row>
            <!-- User Info: Left col -->
            <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                    <!-- <b-avatar
                        :src="customerData.avatar"
                        :text="avatarText(customerData.fullName)"
                        :variant="`light-${resolveUserRoleVariant(customerData.role)}`"
                        size="104px"
                        rounded
                    /> -->
                    <div class="d-flex flex-column ml-1">
                        <div class="mb-1">
                            <h4 class="mb-0">{{ customerData.firstName }} {{ customerData.lastName }}</h4>
                            <span class="card-text">{{ customerData.email }}</span>
                        </div>
                        <!-- <div class="d-flex flex-wrap">
                            <b-button :to="{ name: 'apps-users-edit', params: { id: userData.id } }" variant="primary">
                                Edit
                            </b-button>
                            <b-button variant="outline-danger" class="ml-1"> Delete </b-button>
                        </div> -->
                    </div>
                </div>

                <!-- User Stats -->
                <!-- <div class="d-flex align-items-center mt-2">
                    <div class="d-flex align-items-center mr-2">
                        <b-avatar variant="light-primary" rounded>
                            <feather-icon icon="DollarSignIcon" size="18" />
                        </b-avatar>
                        <div class="ml-1">
                            <h5 class="mb-0">23.3k</h5>
                            <small>Monthly Sales</small>
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                        <b-avatar variant="light-success" rounded>
                            <feather-icon icon="TrendingUpIcon" size="18" />
                        </b-avatar>
                        <div class="ml-1">
                            <h5 class="mb-0">$99.87k</h5>
                            <small>Annual Profit</small>
                        </div>
                    </div>
                </div>  -->
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="UserIcon" class="mr-75" />
                            <span class="font-weight-bold">Username</span>
                        </th>
                        <td class="pb-50">
                            {{ customerData.username }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="CheckIcon" class="mr-75" />
                            <span class="font-weight-bold">Paying Customer</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                            {{ resolveIsPayingCustomer(customerData.isPayingCustomer) }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="CalendarIcon" class="mr-75" />
                            <span class="font-weight-bold">Registered On</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                            {{ customerData.originCreateDate | formatDate }}
                        </td>
                    </tr>
                    <!-- <tr>
                        <th class="pb-50">
                            <feather-icon icon="FlagIcon" class="mr-75" />
                            <span class="font-weight-bold">Country</span>
                        </th>
                        <td class="pb-50">
                            {{ userData.country }}
                        </td>
                    </tr> -->
                    <!-- <tr>
                        <th>
                            <feather-icon icon="PhoneIcon" class="mr-75" />
                            <span class="font-weight-bold">Contact</span>
                        </th>
                        <td>
                            {{ userData.contact }}
                        </td>
                    </tr> -->
                </table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    //  BButton,
    //   BAvatar,
    BRow,
    BCol,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
    components: {
        BCard,
        // BButton,
        BRow,
        BCol,
        // BAvatar,
    },
    props: {
        customerData: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const resolveIsPayingCustomer = isPaying => {
            if (isPaying) {
                return "Paying";
            } else {
                return "Not Paying";
            }
        };
        return {
            avatarText,
            resolveIsPayingCustomer,
            // resolveUserRoleVariant,
        };
    },
};
</script>

<style></style>
