var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justfiy-content-center"},[_c('b-button',{staticClass:"mr-1 w-100",attrs:{"variant":"primary","to":{ name: 'ecommerce-order-add' }}},[_vm._v(" Add Order ")])],1)])],1)],1),_c('b-table',{ref:"refOrderListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchOrders,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"id":("id-order-" + (data.item.id)),"to":{
                    name: 'ecommerce-order-preview',
                    params: { id: data.item.id },
                }}},[_vm._v(" #"+_vm._s(data.item.originId)+" ")])]}},{key:"cell(customerNameSurname)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return undefined},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold",attrs:{"id":("id-order-" + (data.item.id)),"to":{
                        name: 'ecommerce-order-preview',
                        params: { id: data.item.id },
                    }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.customerNameSurname)+" ")])]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.email))])],1)]}},{key:"cell(total)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(data.item.total))+" ")])]}},{key:"cell(originCreateDate)",fn:function(data){return [_c('span',{staticClass:"text-nowrap text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.originCreateDate))+" ")])]}},{key:"cell(status)",fn:function(data){return [[_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveOrderStatusVariantAndIcon(data.value).variant))}},[_vm._v(" "+_vm._s(data.value)+" ")])]]}},{key:"cell(shipment)",fn:function(data){return [(data.value)?_vm._l((data.value),function(item){return _c('div',{key:item.tracking_number},[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("formatShipment")(item.tracking_provider))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.tracking_number))])])}):[_c('div',[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" No shipment data ")])])]]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("order-row-" + (data.item.id) + "-sync-icon"),"icon":"RefreshCwIcon","size":"16"},on:{"click":function($event){return _vm.syncOrder(data.item.id)}}}),_c('b-link',{attrs:{"to":{
                        name: 'ecommerce-order-edit',
                        params: {
                            id: data.item.id,
                        },
                    }}},[_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("order-row-" + (data.item.id) + "-preview-icon"),"icon":"EditIcon","size":"16"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }