<template>
    <div>
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="customerData === undefined">
            <h4 class="alert-heading">Error fetching customer data</h4>
            <div class="alert-body">
                No customer found with this id. Check
                <b-link class="alert-link" :to="{ name: 'ecommerce-customer-list' }"> Customer List </b-link>
                for other customers.
            </div>
        </b-alert>

        <template v-if="customerData">
            <!-- First Row -->
            <b-row>
                <b-col>
                    <customer-view-customer-info-card :customer-data="customerData.customer" />
                </b-col>
            </b-row>

            <customer-orders-list />
        </template>
    </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import CustomerOrdersList from "./CustomerOrdersList.vue";
import customerStoreModule from "../customerStoreModule";
import CustomerViewCustomerInfoCard from "./CustomerViewCustomerInfoCard.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,

        // Local Components
        CustomerViewCustomerInfoCard,
        CustomerOrdersList,
    },
    setup() {
        const toast = useToast();

        const customerData = ref(null);

        const CUSTOMER_APP_STORE_MODULE_NAME = "ecommerce-customer";

        // Register module
        if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
            store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
        });
        // get data for the current customer
        store
            .dispatch("ecommerce-customer/fetchCustomer", router.currentRoute.params.id)
            .then(response => {
                customerData.value = response.data;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "An error has occured",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });

        return {
            customerData,
        };
    },
};
</script>

<style></style>
